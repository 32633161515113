<template>
  <form class="form--full">
    <fieldset>
      <legend>{{$t('PublicSectorCatFilter.filter_title')}}:</legend>
      <div v-for="(category, i) in values" :key="i">
        <input type="checkbox" class="switch" v-model="activeCategories" :id="'category_' + category.tid" :value="category.tid"  />
        <label :for="'category_' + category.tid" class="switch">{{ category.text }}</label>
      </div>
    </fieldset>
  </form>
</template>

<script>

export default {
  name: "PublicSectorCatFilter",
  props: {
    disabled: Boolean,
    classes: String,
    checked: Boolean,
    name: String
  },
  data: function() {
    return {
      values: [],
      activeCategories: null
    };
  },
  watch: {
    activeCategories() {
      this.$store.dispatch('setActiveSectorFilters', this.activeCategories);
    }
  },
  beforeMount: function() {
    let cats = this.$store.state.data.cats;
    let tids = [];
    let values = [];

    for (let i = 0; i < cats.length; i++) {
      tids.push(i);
      values.push({
        text: cats[i],
        tid: i
      });
    }

    this.values = values;
    this.activeCategories = tids

    this.$store.dispatch('setActiveSectorFilters', this.activeCategories);
  }
};
</script>
