<template>
  <header>
    <ul class="breadcrumbs">
      <li><a href="https://tiscreport.org/wales">TISCreport</a></li>
      <li v-for="(breadcrumb, i) in breadcrumbs" :key="i"><a :href="breadcrumb.href" :title="breadcrumb.title">{{breadcrumb.title}}</a></li>
    </ul>
    <a :href="this.getTwitterLink()" class="btn--twitter-share">{{$t('HeaderSection.twitter_button')}}</a>
    <ScrollPercentage></ScrollPercentage>
  </header>
</template>

<script>
import config from "../config";

// Components
import ScrollPercentage from "./ScrollPercentage/ScrollPercentage.vue";

export default {
  name: "HeaderSection",
  components: { ScrollPercentage },
  computed: {
    breadcrumbs: function() {
      return this.getBreadcrumbs(this.$store.state.currentSlide);
    }
  },
  methods: {
    getBreadcrumbs: function(slideIndex) {
      var breadcrumbs = {};

      if (
        typeof config.slides !== "undefined" ||
        typeof config.slides[slideIndex].breadcrumbs === "undefined" ||
        !config.slides[slideIndex].breadcrumbs.length
      ) {
        return;
      }
      let breadcrumbIDs = config.slides[slideIndex].breadcrumbs;

      for (let i = 0; i < breadcrumbIDs.length; i++) {
        let id = breadcrumbIDs[i];
        let title = config.slides[id].sidebar.title;

        if (typeof config.slides[id].sidebar.subtitle !== "undefined") {
          title = title + ": " + config.slides[id].sidebar.subtitle;
        }

        breadcrumbs[id] = {
          title: title,
          href: "#" + config.slides[id].id
        };
      }

      let endTitle = config.slides[slideIndex].sidebar.title;

      if (typeof config.slides[slideIndex].sidebar.subtitle !== "undefined") {
        endTitle = endTitle + ": " + config.slides[slideIndex].sidebar.subtitle;
      }

      // Always tag the current slide onto the end
      breadcrumbs[slideIndex] = {
        title: endTitle,
        href: "#" + config.slides[slideIndex].id
      };

      return breadcrumbs;
    },
    getTwitterLink: function() {
      return "https://twitter.com/intent/tweet?url=" + window.location.href;
    }
  }
};
</script>
