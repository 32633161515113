import { createApp } from 'vue'
import App from './App.vue'
import store from './store'
import translator from "./translator";

// Shift layout depending on screen size
window.addEventListener("load", function() {
    return store.dispatch('updateLayout');
});
window.addEventListener("resize", function() {
    return store.dispatch('updateLayout');
});

const app = createApp(App)
app.use(store)
app.use(translator)
app.mount('#app')