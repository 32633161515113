<template>
  <div>
    <h3 class="center">{{ $t('ContentSignupsPublicSector.title') }}</h3>
    <div class="row">
      <div class="col s12 l8">
        <WalesPublicSectorMap></WalesPublicSectorMap>
      </div>
      <div class="col s12 l4">
        <PublicSectorCatFilter></PublicSectorCatFilter>
      </div>
    </div>
    <div class="banner-row">
      <div class="container">
        <div class="row">
          <div class="col s12 l6">
            <h4 class="s-center">{{ $t('ContentSignupsPublicSector.subtitle') }}</h4>
            <div v-if="loading" class="loading">
              <LoaderSpinner></LoaderSpinner>
              <p>{{ $t('ContentSignupsPublicSector.loading') }}</p>
            </div>
            <CounterItems v-else :counterData="counterData"></CounterItems>
          </div>
          <div class="col s12 l6">
            <p>{{ $t('ContentSignupsPublicSector.desc') }}</p>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>

// Components
import CounterItems from "../../Counter/CounterItems.vue";
import WalesPublicSectorMap from "../../charts/WalesPublicSectorMap.vue";
import PublicSectorCatFilter from "../../forms/PublicSectorCatFilter.vue";
import LoaderSpinner from "../../LoaderSpinner.vue";

export default {
  name: "ContentSignupsPublicSector",
  components: {
    CounterItems,
    WalesPublicSectorMap,
    PublicSectorCatFilter,
    LoaderSpinner
  },
  data() {
    return {
      counterData: {
        labels: [],
        items: [{ figures: [0] }]
      },
      loading: false,
    };
  },
  computed: {
    activeCategories: function() {
      return this.$store.state.activeSectorFilters;
    }
  },
  beforeMount() {
    this.counterData.labels.push(this.$t('ContentSignUps.signed_up'))
    this.fetchData();
  },
  watch: {
    activeCategories: function() {
      this.fetchData();
    }
  },
  methods: {
    fetchData: function () {
      this.loading = true
      this.$store.dispatch('fetchSupplierData').then((hash) => {
        this.counterData.items[0].figures[0] = this.$store.state.supplierData[hash].registered
        this.loading = false
      })
    },
  }
};
</script>
