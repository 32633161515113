import { createI18n } from 'vue-i18n'
import en from './translations/en.json'
import cy from './translations/cy.json'

export default createI18n({
    locale: 'en',
    messages: {
        en: en,
        cy: cy,
    },
});