<template>
  <aside class="sidebar col s12 l4">
    <div class="container sidebar--content">
      <h4 class="slide--subtitle" v-if="item.subtitle !== undefined">{{$t(item.subtitle)}}</h4>
      <h2 class="slide--title">{{$t(item.title)}}</h2>
      <p class="slide--desc">{{$t(item.desc)}}</p>
      <a class="slide--cta btn dark" href="https://tiscreport.org/sites/default/files/TiscReportWelshCOPProgress.csv">{{$t('SidebarSection.download_button')}}</a>
    </div>
    <div v-if="nextCta" class="sidebar--next">
      <a :href="nextCta.href">{{nextCta.text}}</a>
    </div>
  </aside>
</template>

<script>
export default {
  name: "SidebarSection",
  props: ["item", "nextCta"],
};
</script>
