<template>
  <div class="container">
    <div class="section">
      <h3>{{$t('ContentMoreInfo.title')}}</h3>
      <div class="section">
      <ul>
        <li v-for="(item, i) in listItems" class="tick" :key="i">{{item}}</li>
      </ul>
    </div>
    <h5>{{$t('ContentMoreInfo.subtitle')}}</h5>
    <p>{{$t('ContentMoreInfo.desc')}}</p>
    <a href="https://tiscreport.org/user/register" class="btn">{{$t('ContentMoreInfo.button')}}</a>
    </div>
  </div>
</template>

<script>
export default {
  name: "ContentMoreInfo",
  data: function() {
    return {
      listItems: [
        this.$t('ContentMoreInfo.want_to_supply'),
        this.$t('ContentMoreInfo.do_supply'),
        this.$t('ContentMoreInfo.public_sector'),
        this.$t('ContentMoreInfo.want_to_follow'),
      ]
    };
  }
};
</script>
