<template>
  <div id="private-sector-msa" class="chart">
    <SunburstChart :chartData="chartData"></SunburstChart>
    <div class="chart--key-container">
      <h4>{{total.toLocaleString() + ' ' + $t('VoluntaryBodiesMSA.title')}}</h4>
      <ul class="chart--key">
        <li v-for="(item, i) in chartKeys" :key="i">{{item}}</li>
      </ul>
    </div>
  </div>
</template>

<script>

// Components
import SunburstChart from "./default/SunburstChart.vue";

export default {
  name: "VoluntaryBodiesMSA",
  components: { SunburstChart },
  computed: {
    data: function () {
      return this.$store.state.data.voluntary_bodies.msa;
    },
    total: function () {
      return this.data.totals.total;
    },
    chartKeys: function() {
      return [
        `${this.data.totals.not_required.toLocaleString()} ` + this.$t('ChartMsa.label_not_required'),
        `${this.data.not_required.has_statement.toLocaleString()} ` + this.$t('ChartMsa.label_not_required_statement'),
        `${this.data.not_required.no_statement.toLocaleString()} ` + this.$t('ChartMsa.label_not_required_no_statement'),
        `${this.data.totals.required.toLocaleString()} ` + this.$t('ChartMsa.label_required'),
        `${this.data.required.has_statement.toLocaleString()} ` + this.$t('ChartMsa.label_required_statement'),
        `${this.data.required.no_statement.toLocaleString()} ` + this.$t('ChartMsa.label_required_no_statement'),
      ]
    },
    chartData: function () {
      return {
        color: "transparent",
        showLabels: true,
        children: [
          {
            color: "rgba(0, 158, 175, 0.7)",
            children: [
              {
                color: "rgba(103, 58, 183, 0.7)",
                size: this.data.not_required.has_statement
              },
              {
                color: "rgba(0,0,0,0.2)",
                size: this.data.not_required.no_statement
              }
            ]
          },
          {
            color: "rgba(255, 165, 0, 0.7)",
            children: [
              {
                color: "rgba(87, 168, 64, 0.7)",
                size: this.data.required.has_statement
              },
              {
                color: "rgba(161, 22, 22, 0.7)",
                size: this.data.required.no_statement
              }
            ]
          }
        ]
      };
    },
  }
};
</script>