// Slides configuration
// breadscrumbs are the item indexes
const config = {
  reportTitle: "ReportTitle.title",
  data: {},
  slides: [
    {
      id: "who-has-signed-up",
      component: "ContentSignUps",
      sidebar: {
        title: "SectionSignUps.title",
        desc: "SectionSignUps.desc",
      },
      breadcrumbs: [0]
    },
    {
      id: "sign-ups-welsh-public-bodies",
      component: "ContentSignupsPublicSector",
      sidebar: {
        subtitle: "SectionSignupsPublicSector.subtitle",
        title: "SectionSignupsPublicSector.title",
        desc: "SectionSignupsPublicSector.desc",
      },
      breadcrumbs: [0]
    },
    {
      id: "sign-ups-private-sector-suppliers",
      component: "ContentSuppliersByCountry",
      sidebar: {
        subtitle: "SectionSuppliersByCountry.subtitle",
        title: "SectionSuppliersByCountry.title",
        desc: "SectionSuppliersByCountry.desc",
      },
      breadcrumbs: [0]
    },
    {
      id: "uk-modern-slavery-act-private-sector-suppliers",
      component: "ContentSuppliersMSA",
      sidebar: {
        subtitle: "SectionSuppliersMSA.subtitle",
        title: "SectionSuppliersMSA.title",
        desc: "SectionSuppliersMSA.desc",
      },
      breadcrumbs: [0, 2]
    },
    {
      id: "sign-ups-voluntary-bodies",
      component: "ContentVoluntaryBodiesByCountry",
      sidebar: {
        subtitle: "SectionVoluntaryBodiesByCountry.subtitle",
        title: "SectionVoluntaryBodiesByCountry.title",
        desc: "SectionVoluntaryBodiesByCountry.desc",
      },
      breadcrumbs: [0]
    },
    {
      id: "uk-modern-slavery-act-voluntary-bodies",
      component: "ContentVoluntaryBodiesMSA",
      sidebar: {
        subtitle: "SectionVoluntaryBodiesMSA.subtitle",
        title: "SectionVoluntaryBodiesMSA.title",
        desc: "SectionVoluntaryBodiesMSA.desc",
      },
      breadcrumbs: [0, 4]
    },
    {
      id: "want-more-information",
      component: "ContentMoreInfo",
      sidebar: {
        title: "SectionMoreInfo.title",
        desc: "SectionMoreInfo.desc",
      },
      breadcrumbs: [0]
    }
  ]
};

export default config;
