<template>
  <div>
    <div class="container">
      <h3 class="s-center">{{$t('ContentVoluntaryBodiesByCountry.title')}}</h3>
    <div class="section">
    <VoluntaryBodiesByCountry></VoluntaryBodiesByCountry>
    </div>
  </div>
    <div class="banner-row">
      <div class="row container">
        <div class="col s12 l7">
          <h4>{{$t('ContentByCountry.subtitle')}}</h4>
          <p>{{$t('ContentByCountry.desc')}}</p>
        </div>
        <div class="col s12 l5">
          <a href="#uk-modern-slavery-act-voluntary-bodies" class="chevron-down">{{$t('ContentByCountry.button')}}</a>
        </div>
      </div>
    </div>
  </div>
</template>

<script>

// Components
import VoluntaryBodiesByCountry from "../../charts/VoluntaryBodiesByCountry.vue";

export default {
  name: "ContentVoluntaryBodiesByCountry",
  components: { VoluntaryBodiesByCountry }
};
</script>
