<template>
  <div id="title" class="col s12 l4">
    <h1>{{ $t('ReportTitle.title') }}</h1>
  </div>
</template>

<script>
import config from "../config";
export default {
  name: "ReportTitle",
  data: () => {
    return {
      title: config.reportTitle
    };
  }
};
</script>
