<template>
  <div style="height:800px; width:100%">
    <l-map :options="mapOptions">
      <l-geo-json v-if="countryGeoJson" :geojson="countryGeoJson" :options="countryGeoJsonOptions" @ready="setBounds"></l-geo-json>
      <l-geo-json v-if="signedUpGeoJson" :geojson="signedUpGeoJson" :options="signedUpGeoJsonOptions">
        <l-popup>
          <span v-if="selectedFeature">
            <a :href="selectedFeature.ENTITY_URL" target="_blank">{{ selectedFeature.ENTITY_TITLE }}</a>
          </span>
        </l-popup>
      </l-geo-json>
    </l-map>
  </div>
</template>

<script>

import "leaflet/dist/leaflet.css";
import { LMap, LGeoJson, LPopup } from "@vue-leaflet/vue-leaflet";

import countryGeoJson from "../../assets/wales-la-geo.json";

export default {
  name: "WalesPublicSectorMap",
  components: {
    LMap, LGeoJson, LPopup
  },
  data() {
    return {
      countryGeoJson: countryGeoJson,
      signedUpGeoJson: null,
      selectedFeature: null,
      mapOptions: {
        zoomControl: false,
        attributionControl: false,
        dragging: false,
        doubleClickZoom: false,
        scrollWheelZoom: false,
      },
      countryGeoJsonOptions: {
        style: function() {
          return {
            fillColor: "#652429",
            weight: 2,
            opacity: 1,
            color: "#27181c",
            dashArray: "3",
            fillOpacity: 0.7
          };
        },
      },
      signedUpGeoJsonOptions: {
        onEachFeature: (feature, layer) => {
          layer.on({
            click: () => {
              this.selectedFeature = feature.properties;
            },
          });
        },
      },
    };
  },
  computed: {
    activeCategories: function() {
      return this.$store.state.activeSectorFilters;
    },
  },
  beforeMount() {
    this.setSignedUpGeoJson();
  },
  watch: {
    activeCategories() {
      this.setSignedUpGeoJson();
    },
  },
  methods: {
    setSignedUpGeoJson() {
      const data = this.$store.state.data.welsh_public_bodies.engagement.geo_data;
      const features = data.features ?? [];

      // Filter features by active categories
      this.signedUpGeoJson = {
        type: "FeatureCollection",
        features: features.filter(feature => {
          const category = feature.properties.ENTITY_TYPE;
          return this.activeCategories.includes(category);
        })
      }
    },
    setBounds(layer) {
      layer._map.fitBounds(layer.getBounds());
    }
  }
};
</script>
<style scoped>
  .leaflet-container {
    background: transparent;
  }
</style>