<template>
  <div id="app" :class="appClass">
    <div v-if="loading" class="loading-screen">
      <div class="loading">
        <LoaderSpinner></LoaderSpinner>
        <p>{{$t('App.loading')}}</p>
      </div>
    </div>
    <div v-else-if="error" class="loading-screen">
      <div class="loading">
        <p>{{$t('App.error')}}</p>
      </div>
    </div>
    <div v-else>
      <HeaderSection ref="header"></HeaderSection>
      <MainSection></MainSection>
    </div>
  </div>
</template>

<script>

// Components
import HeaderSection from "./components/HeaderSection.vue";
import MainSection from "./components/MainSection.vue";
import LoaderSpinner from "./components/LoaderSpinner.vue";

export default {
  name: "app",
  components: {
    HeaderSection,
    MainSection,
    LoaderSpinner
  },
  data: function() {
    return {
      error: false
    };
  },
  computed: {
    showDownloadForm: function() {
      return this.$store.state.showDownloadForm;
    },
    loading: function() {
      return this.$store.state.loading;
    },
    appClass: function() {
      if (this.showDownloadForm) {
        return "modal-open";
      }
      return "modal-closed";
    }
  },
  beforeMount: function() {
    this.$store.dispatch('fetchData');
  },
  mounted: function() {
    // If statement for browser compatibility
    if (document.addEventListener) {
      document.addEventListener("click", this.anchorOnClick, false);
    } else {
      document.attachEvent("onclick", this.anchorOnClick);
    }
  },
  methods: {
    anchorOnClick: function(event) {
      // In page anchors need offsetting by the header height as it has a fixed position
      if (
          typeof event.target.hash === "undefined" ||
          event.target.hash.length < 2
      ) {
        return;
      }

      let id = event.target.hash.replace("#", "");
      let element = document.getElementById(id);
      let header = document.getElementsByTagName("header")[0]; // Assuming that the main header is the first header element on the page

      if (element === null) {
        return;
      }

      event.preventDefault();

      // Setting document.documentElement and document.body for cross-browser compatibility
      document.documentElement.scrollTop =
          element.offsetTop - header.offsetHeight;
      document.body.scrollTop = element.offsetTop - header.offsetHeight;
    }
  }
};
</script>
<style lang="scss">
@import "./assets/scss/main.scss";
</style>