<template>
  <div v-if="chartData" id="private-sector-by-country" class="chart row">
    <Bar :data="chartData" :options="chartOptions"></Bar>
  </div>
</template>

<script>

// Components
import { Bar } from 'vue-chartjs'
import { Chart as ChartJS, Title, Tooltip, Legend, BarElement, CategoryScale, LinearScale } from 'chart.js'

ChartJS.register(Title, Tooltip, Legend, BarElement, CategoryScale, LinearScale)

export default {
  name: "ChartByCountry",
  components: { Bar },
  computed: {
    chartData() {
      let data = this.$store.state.data.private_sector.engagement;
      let chartData = {};

      Object.keys(data).forEach(function(dataset) {
        chartData[dataset] = [];
        Object.keys(data[dataset]).forEach(function(country) {
          chartData[dataset].push(data[dataset][country]);
        });
      });

      const labels = [
        this.$t('ChartByCountry.en_label'),
        this.$t('ChartByCountry.ni_label'),
        this.$t('ChartByCountry.sc_label'),
        this.$t('ChartByCountry.cy_label'),
        this.$t('ChartByCountry.global_label'),
      ];

      if (data.registered.unknown > 0) {
        labels.push(this.$t('ChartByCountry.unknown_label'),);
      }

      return {
        labels: labels,
        datasets: [
          {
            label: this.$t('PrivateSectorByCountry.registered_title'),
            data: chartData.registered,
            backgroundColor: "rgba(0, 158, 175, 0.7)",
            borderColor: "rgba(0, 158, 175, 1)"
          }
        ]
      };
    },
    chartOptions() {
      return {
        responsive: true,
        indexAxis: 'y',
        plugins: {
          legend: {
            position: 'bottom',
            labels: {
              font: {
                family: "'Open Sans',sans-serif",
                size: 15
              },
              color: "white",
            },
          },
          tooltip: {
            titleFont: {
              size: 18,
              family: "'Crete Round', serif"
            },
            titleMarginBottom: 12,
            bodyFont: {
              size: 14,
            },
            cornerRadius: 3,
            padding: 18,
            displayColors: false,
            backgroundColor: "rgba(0,0,0,0.9)"
          },
        },
        scales: {
          y: {
            ticks: {
              beginAtZero: true,
              font: {
                family: "'Open Sans',sans-serif",
                size: 15
              },
              color: "white",
            },
            grid: {
              color: "rgba(255,255,255,0.1)",
            },
          }
        },
      };
    },
  },
};
</script>
