<template>
  <div>
    <div class="container">
      <h3 class="s-center">{{$t('ContentSuppliersByCountry.title')}}</h3>
      <div class="section">
        <PrivateSectorByCountry></PrivateSectorByCountry>
      </div>
    </div>
    <div class="banner-row">
      <div class="row container">
        <div class="col s12 l7">
          <h4>{{$t('ContentByCountry.subtitle')}}</h4>
          <p>{{$t('ContentByCountry.desc')}}</p>
        </div>
        <div class="col s12 l5">
          <a href="#uk-modern-slavery-act-private-sector-suppliers" class="chevron-down">{{$t('ContentByCountry.button')}}</a>
        </div>
      </div>
    </div>
  </div>
</template>

<script>

// Components
import PrivateSectorByCountry from "../../charts/PrivateSectorByCountry.vue";

export default {
  name: "ContentSuppliersByCountry",
  components: { PrivateSectorByCountry }
};
</script>
