<template>
  <div>
    <h3 class="center">{{ $t('ContentMSA.title') }}</h3>
    <VoluntaryBodiesMSA></VoluntaryBodiesMSA>
    <div class="banner-row">
      <div class="row container">
        <div class="col s12 l5">
          <a href="#sign-ups-voluntary-bodies" class="chevron-up">{{ $t('ContentMSA.return') }}</a>
        </div>
        <div class="col s12 l7">
          <h4>{{ $t('ContentMSA.subtitle') }}</h4>
          <p>{{ $t('ContentMSA.desc') }}</p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>

// Components
import VoluntaryBodiesMSA from "../../charts/VoluntaryBodiesMSA.vue";

export default {
  name: "ContentVoluntaryBodiesMSA",
  components: { VoluntaryBodiesMSA }
};
</script>
