<template>
  <li :id="item.id" :class="getActiveClass()">
    <transition name="fade" appear>
    <article class="slide row">
      <SidebarSection :item="item.sidebar" :nextCta="nextCta"></SidebarSection>
      <div class="content col s12 l8">
        <component :is="item.component"></component>
      </div>
    </article>
    </transition>
  </li>
</template>

<script>
import config from "../../config";

// Components
import SidebarSection from "../SidebarSection.vue";
import {
  ContentSignUps,
  ContentSignupsPublicSector,
  ContentSuppliersByCountry,
  ContentVoluntaryBodiesByCountry,
  ContentMoreInfo,
  ContentSuppliersMSA,
  ContentVoluntaryBodiesMSA
} from "./content";

export default {
  name: "SlideItem",
  components: {
    SidebarSection,
    ContentSignUps,
    ContentSignupsPublicSector,
    ContentSuppliersByCountry,
    ContentVoluntaryBodiesByCountry,
    ContentMoreInfo,
    ContentSuppliersMSA,
    ContentVoluntaryBodiesMSA
  },
  props: ["item", "index", "active"],
  computed: {
    nextCta: function() {
      if (this.$store.state.currentSlide === this.index) {
        let nextIndex = this.index + 1;

        if (typeof config.slides[nextIndex] === "undefined") {
          nextIndex = 0;
        }

        let text = this.$t(config.slides[nextIndex].sidebar.title);

        if (typeof config.slides[nextIndex].sidebar.subtitle !== "undefined") {
          text = text + ": " + this.$t(config.slides[nextIndex].sidebar.subtitle);
        }

        return {
          text: text,
          href: "#" + config.slides[nextIndex].id
        };
      }
      return false;
    }
  },
  mounted: function() {
    this.updateOffset();
    window.addEventListener("resize", this.updateOffset);
    window.addEventListener("orientationchange", this.updateOffset);
  },
  methods: {
    updateOffset: function() {
      // Set the rendered position on the root and access from the parent component
      this.$store.dispatch("updateSlidePos", {index: this.index, pos: this.$el.offsetTop});
    },
    getActiveClass: function() {
      if (this.active) {
        return "active";
      } else {
        return "not-active";
      }
    }
  }
};
</script>
