<template>
  <div>
    <div class="container">
      <h3 class="s-center">{{$t('ContentSignUps.live_report_title')}}</h3>
      <h4 class="s-center">{{$t('ContentSignUps.live_report_desc')}}</h4>
      <div class="section">
      <RegisteredByTypeChart></RegisteredByTypeChart>
    </div>
    </div>
    <div class="banner-row">
      <div class="container">
        <CounterItems :counterData="counterData" :counterOptions="counterOptions"></CounterItems>
      </div>
      <h5 class="center">{{$t('ContentSignUps.other_title')}}: <span>{{otherRegTotal}}</span></h5>
      <p class="center"><small>*{{$t('ContentSignUps.other_desc')}}</small></p>
    </div>
  </div>
</template>

<script>

// Components
import RegisteredByTypeChart from "../../charts/RegisteredByTypeChart.vue";
import CounterItems from "../../Counter/CounterItems.vue";

export default {
  name: "ContentSignUps",
  components: {
    RegisteredByTypeChart,
    CounterItems
  },
  data: () => {
    return {
      counterData: {
        labels: [],
        items: []
      }
    };
  },
  computed: {
    otherRegTotal: function() {
      return this.$store.state.data.who_signed_up.other.registered;
    }
  },
  beforeMount: function() {
    this.counterData.labels.push(this.$t('ContentSignUps.signed_up'))
    this.getFigures();
  },
  methods: {
    getFigures: function() {
      var data = this.$store.state.data.who_signed_up;


      this.counterData.items = [
        {
          title: this.$t('ContentSignUps.label_public'),
          figures: [data.public_bodies.registered /*data.public_bodies.total*/],
          cta: {
            label: this.$t('ContentSignUps.view_breakdown'),
            path: "#sign-ups-welsh-public-bodies",
            cssClass: "red"
          }
        },
        {
          title: this.$t('ContentSignUps.label_supplier'),
          figures: [
            data.private_sector.registered /*data.private_sector.total*/
          ],
          cta: {
            label: this.$t('ContentSignUps.view_breakdown'),
            path: "#sign-ups-private-sector-suppliers"
          }
        },
        {
          title: this.$t('ContentSignUps.label_voluntary'),
          figures: [
            data.voluntary_bodies.registered
            /*data.voluntary_bodies.total*/
          ],
          cta: {
            label: this.$t('ContentSignUps.view_breakdown'),
            path: "#sign-ups-voluntary-bodies",
            cssClass: "green"
          }
        }
      ];
    }
  }
};
</script>
