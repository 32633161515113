<template>
  <div class="counter">
    <div v-if="topTitles.length > 0" class="row">
      <div :class="colClass">&nbsp;</div>
      <div :class="colClass" v-for="(title, i) in topTitles" :key="i">
        <p class="counter--label--top label center">{{ title }}</p>
      </div>
    </div>
    <div class="row">
      <div :class="colClass">
        <p class="counter--label" v-for="(label, i) in counterData.labels" :key="i">{{ label }}</p>
      </div>
      <div :class="colClass" v-for="(item, i) in items" :key="i">
        <CounterCol :item="item"></CounterCol>
      </div>
    </div>
  </div>
</template>

<script>

// Components
import CounterCol from "./CounterCol.vue";

export default {
  name: "CounterItems",
  components: { CounterCol },
  props: ["counterData", "counterOptions"],
  data: function() {
    return {
      topTitles: [],
      items: []
    };
  },
  computed: {
    layout: function() {
      // store.getLayout();
      return this.$store.state.layout;
    },
    colClass: function() {
      let count = this.counterData.items.length + 1;
      return "col s12 l" + Math.floor(12 / count);
    }
  },
  watch: {
    layout: function() {
      this.sortData();
    }
  },
  beforeMount: function() {
    this.sortData();
  },
  methods: {
    sortData: function() {
      var items = this.counterData.items.slice(0);

      if (this.layout === "desktop") {
        // Modify items and move titles to a new data property to display seperately
        for (let i = 0; i < items.length; i++) {
          if (typeof items[i].title !== "undefined") {
            this.topTitles[i] = items[i].title;
          }
        }
        this.items = items;
      } else {
        this.topTitles = [];
        this.items = items;
      }
    }
  }
};
</script>
