<template>
  <div class="scroll-percentage">
    <div class="inner" :style="'height:' + height + 'px; width:' + percent + '%;'" :title="$t('ScrollPercentage.title') + percent + '%'"></div>
  </div>
</template>

<script>
export default {
  name: "ScrollPercentage",
  props: {
    height: {
      default: 2,
      type: Number
    }
  },
  data: function() {
    return {
      percent: 0
    };
  },
  mounted: function() {
    document.addEventListener("scroll", event => this.scrollHandler(event));
  },
  methods: {
    scrollHandler: function() {
      var h = document.documentElement,
        b = document.body,
        st = "scrollTop",
        sh = "scrollHeight";

      this.percent =
        (h[st] || b[st]) / ((h[sh] || b[sh]) - h.clientHeight) * 100;
    }
  }
};
</script>
