import {createStore} from 'vuex'
import axios from 'axios'
import md5 from 'md5'

const apiHost = function () {
    //return 'https://tisc.ross.test';
    return 'https://tiscreport.org';
}

const store = {
    state() {
        return {
            data: {},
            currentSlide: 0,
            slidePos: [],
            activeSectorFilters: [],
            showDownloadForm: false,
            layout: "desktop",
            supplierData: {},
        }
    }, mutations: {
        SET_API_DATA(state, data) {
            state.data = data
        }, SET_SUPPLIER_API_DATA(state, value) {
            state.supplierData[value.hash] = value.data
        }, SET_LOADING(state, loading) {
            state.loading = loading
        }, SET_CURRENT_SLIDE(state, slide) {
            state.currentSlide = slide
        }, SET_ACTIVE_SECTOR_FILTERS(state, filters) {
            state.activeSectorFilters = filters.sort()
        }, SET_SLIDE_POS(state, value) {
            state.slidePos[value.index] = value.pos
        }, SET_LAYOUT(state, layout) {
            state.layout = layout
        }
    }, actions: {
        fetchData({commit}) {
            commit('SET_LOADING', true)

            return axios.get(apiHost() + '/api/report/welsh-cop').then(res => {
                commit('SET_LOADING', false)
                return commit('SET_API_DATA', res.data)
            })
        }, fetchSupplierData({state, commit}) {

            const activeFilters = state.activeSectorFilters.length > 0 ? state.activeSectorFilters : Object.keys(state.data.cats)
            const hash = md5(JSON.stringify(activeFilters))

            if (state.supplierData[hash]) {
                return Promise.resolve(hash)
            }

            let categories = [];
            for (let i = 0; i < activeFilters.length; i++) {
                categories.push(state.data.cats[activeFilters[i]])
            }

            return axios.get(apiHost() + '/api/report/welsh-cop/suppliers', {params: {cats: categories}}).then(res => {
                return commit('SET_SUPPLIER_API_DATA', {hash: hash, data: res.data})
            }).then(() => {
                return Promise.resolve(hash)
            })

        }, updateCurrentSlide({commit}, slide) {
            commit('SET_CURRENT_SLIDE', slide)
        }, setActiveSectorFilters({commit}, filters) {
            commit('SET_ACTIVE_SECTOR_FILTERS', filters)
        }, updateSlidePos({commit}, value) {
            commit('SET_SLIDE_POS', value)
        }, updateLayout({commit}) {
            commit('SET_LAYOUT', window.innerWidth > 992 ? 'desktop' : 'mobile')
        }
    },
}

export default createStore(store);
