<template>
  <span>{{displayNumber}}</span>
</template>

<script>
export default {
  name: "CounterItem",
  props: ["number"],
  data: function() {
    return {
      startNumber: 0,
      displayNumber: 0,
      interval: false
    };
  },
  mounted: function() {
    this.countUp(this.startNumber, this.number);
  },
  methods: {
    countUp: function(start, end) {
      clearInterval(this.interval);

      if (start == end) {
        return;
      }

      this.interval = window.setInterval(
          function() {
            if (start != end) {
              var change = (this.number - this.displayNumber) / 10;

              change = change >= 0 ? Math.ceil(change) : Math.floor(change);

              this.displayNumber = this.displayNumber + change;
            }
          }.bind(this),
          5
      );
    }
  }
};
</script>
