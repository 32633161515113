<template>
  <div :data-col="col">
  <p v-if="layout === 'mobile'" class="label center">{{item.title}}</p>
  <ul class="counter--item">
    <li v-for="(figure, i) in item.figures" :key="i"><CounterItem :number="figure"></CounterItem></li>
  </ul>
  <p class="counter--cta" v-if="item.cta !== undefined">
    <a :class="'btn--sml ' + item.cta.cssClass" :href="item.cta.path">{{item.cta.label}}</a>
  </p>
</div>
</template>

<script>

// Components
import CounterItem from "./CounterItem.vue";

export default {
  name: "CounterCol",
  components: { CounterItem },
  props: ["item", "col"],
  computed: {
    layout: function() {
      // store.getLayout();
      return this.$store.state.layout
    }
  }
};
</script>
