<template>
  <ul id="slides">
    <SlideItem
      is="Slide"
      v-for="(item, index) in items"
      :item="item"
      :index="index"
      :key="item.id"
      :active="assignActiveStatus(index)"
    ></SlideItem>
  </ul>
</template>

<script>
import config from "../config";

// Components
import SlideItem from "./slides/SlideItem.vue";

export default {
  name: "SlideItems",
  components: { SlideItem },
  data: () => {
    return {
      items: config.slides,
    };
  },
  computed: {
    slidePos: function() {
      return this.$store.state.slidePos;
    },
    currentSlide: function() {
      return this.$store.state.currentSlide;
    }
  },
  mounted: function() {
    // Add event listener to detect which slide is in the viewport
    window.addEventListener("scroll", this.handleScroll);
  },
  beforeUnmount: function() {
    window.removeEventListener("scroll", this.handleScroll);
  },
  methods: {
    handleScroll: function() {
      // When the top of the slide reaches above the threshold it will become "active"
      // yThreshold = 50% of the screen height
      var currentPos =
        window.pageYOffset ||
        document.documentElement.scrollTop ||
        document.body.scrollTop ||
        0;
      var yThreshold = window.innerHeight / 2;
      var yTrigger = currentPos + yThreshold;
      var currentSlide = this.currentSlide;

      for (var i = 0; i < this.slidePos.length; i++) {
        if (yTrigger >= this.slidePos[i]) {
          currentSlide = i;
        } else {
          break;
        }
      }
      if (currentSlide !== this.currentSlide) {
        this.updateCurrentSlide(currentSlide);
      }
    },
    updateCurrentSlide: function(index) {
      this.currentSlide = index;
      // Update the store so we can update other components if necessary
      this.$store.dispatch("updateCurrentSlide", index);
    },
    assignActiveStatus: function(index) {
      return index === this.currentSlide;
    }
  }
};
</script>
