<template>
  <main class="row">
    <ReportTitle/>
    <SlideItems/>
  </main>
</template>

<script>
// Components
import ReportTitle from "./ReportTitle.vue";
import SlideItems from "./SlideItems.vue";

export default {
  name: "MainSection",
  components: {
    ReportTitle,
    SlideItems
  }
};
</script>
