<template>
  <div :id="id" class="chart" :style="'max-width: ' + chartHeight + 'px; max-height: ' + chartHeight + 'px;'"></div>
</template>
<script>
// See https://www.npmjs.com/package/sunburst-chart for data structure example and usage
import Sunburst from "sunburst-chart";

export default {
  name: "SunburstChart",
  props: ["chartData", "height", "width"],
  data: function() {
    return {
      id: 0,
      chartHeight: 600,
      chartWidth: 600
    };
  },
  beforeMount: function() {
    this.id = "chart-" + this._uid;

    if (this.height !== undefined && Number.isInteger(this.height)) {
      this.chartHeight = this.height;
    }
    if (this.width !== undefined && Number.isInteger(this.width)) {
      this.chartWidth = this.width;
    }
    if (this.chartWidth > window.innerWidth) {
      this.chartHeight = window.innerWidth;
      this.chartWidth = window.innerWidth;
    }
  },
  mounted: function () {
    Sunburst()
        .data(this.chartData)
        .size("size")
        .width(this.chartWidth)
        .height(this.chartHeight)
        .showLabels(false)
        .showTooltip(false)
        .color("color")(this.$el);
  }
};
</script>
