<template>
  <div v-if="chartData" id="registered-by-type-chart" class="chart row">
    <Bar :data="chartData" :options="chartOptions" />
      <ul class="chart--key row">
        <li v-for="(item, i) in chartData.labels" :key="i" class="col s12 l3">
          <p>{{item}}</p>
          <ul>
            <li><span>{{$t('RegisteredByTypeChart.signed_up')}}</span></li>
            <li><span>{{$t('RegisteredByTypeChart.tisc_registered')}}</span></li>
          </ul>
        </li>
      </ul>
  </div>
</template>

<script>

// Components
import { Bar } from 'vue-chartjs'
import { Chart as ChartJS, Title, Tooltip, Legend, BarElement, CategoryScale, LinearScale } from 'chart.js'

ChartJS.register(Title, Tooltip, Legend, BarElement, CategoryScale, LinearScale)

export default {
  name: "RegisteredByTypeChart",
  components: { Bar },
  computed: {
    chartOptions() {
      return {
        responsive: true,
        plugins: {
          legend: {
            display: false
          },
          tooltip: {
            titleFont: {
              size: 18,
              family: "'Crete Round', serif"
            },
            titleMarginBottom: 12,
            bodyFont: {
              size: 14,
            },
            cornerRadius: 3,
            padding: 18,
            displayColors: false,
            backgroundColor: "rgba(0,0,0,0.9)"
          },
        },
        scales: {
          x: {
            stacked: true,
            ticks: {
              display: false
            },
            grid: { display: false }
          },
          y: {
            stacked: true,
            ticks: {
              beginAtZero: true,
              font: {
                family: "'Open Sans',sans-serif",
                size: 15
              },
              color: "white",
            },
            grid: {
              color: "rgba(255,255,255,0.1)",
            },
          }
        }
      }
    },
    chartData() {
      const data = this.$store.state.data.who_signed_up;
      const registeredData = [
        data.public_bodies.registered - data.public_bodies.verified,
        data.private_sector.registered - data.private_sector.verified,
        data.voluntary_bodies.registered - data.voluntary_bodies.verified,
        data.other.registered
      ];
      const verifiedData = [
        data.public_bodies.verified,
        data.private_sector.verified,
        data.voluntary_bodies.verified,
      ];

      return {
        labels: [
          this.$t('RegisteredByTypeChart.label_public'),
          this.$t('RegisteredByTypeChart.label_supplier'),
          this.$t('RegisteredByTypeChart.label_voluntary'),
          this.$t('RegisteredByTypeChart.label_other'),
        ],
        datasets: [
          {
            barPercentage: window.innerWidth < 992 ? 0.9 : 0.6,
            label: this.$t('RegisteredByTypeChart.dataset_tisc'),
            data: verifiedData,
            backgroundColor: [
              "rgba(119, 22, 20, 0.7)",
              "rgba(0, 95, 105, 0.7)",
              "rgba(52, 101, 38, 0.7)"
            ],
            borderColor: [
              "rgba(161, 22, 22, 1)",
              "rgba(0, 158, 175, 1)",
              "rgba(87, 168, 64, 1)"
            ],
            borderWidth: 2
          },
          {
            barPercentage: window.innerWidth < 992 ? 0.9 : 0.6,
            label: this.$t('RegisteredByTypeChart.signed_up'),
            data: registeredData,
            backgroundColor: [
              "rgba(161, 22, 22, 0.7)",
              "rgba(0, 158, 175, 0.7)",
              "rgba(87, 168, 64, 0.7)",
              "rgba(103, 58, 183, 0.7)"
            ],
            borderColor: [
              "rgba(161, 22, 22, 1)",
              "rgba(0, 158, 175, 1)",
              "rgba(87, 168, 64, 1)",
              "rgba(103, 58, 183, 1)"
            ],
            borderWidth: 2
          }
        ]
      };
    }
  },
};
</script>
